import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"

import SEO from "../components/seo"
import { MHPageProps } from "../types"
import { Avatar } from "../components/Avatar"
import { H1, H3 } from "../components/Markdown"
import { AboutItem, AboutExperienceItem } from "../components/AboutItem"
import { FilteredContent, FilteredLI } from "../components/ContentFilter"
import { getExcerpt } from "../excerptHelper"
import { ListOfSocialNetworks } from "../components/ListOfSocialNetworks"

class AboutPage extends React.Component<MHPageProps> {
  render() {
    const { bio } = this.props.data

    return (
      <>
        <SEO
          title="About me"
          description={bio.frontmatter.description || getExcerpt(bio, true)}
        />

        <div className="about-body">
          <article className="about-body__text">
            <H1>Hi, I’m Marco. </H1>
            <MDXRenderer>{bio.body}</MDXRenderer>

            <H3>Me in other places</H3>
            <p>
              I can be found in many places on the internet under the username
              marcohamersma. I don't post as often as I used to, but here's a
              list of places you can find me:
            </p>

            <ul className="n-spacing-top-small social-list n-clear">
              <ListOfSocialNetworks />
            </ul>

            <H3>
              Have a nice{" "}
              <FilteredContent for="sound" exclusive>
                and sonically pleasing
              </FilteredContent>{" "}
              day!
            </H3>
          </article>

          <aside className="n-type-small about-body__deets">
            <Avatar />

            <section className="">
              <h2>All the facts</h2>
              <span className="n-type-smallest n-color-mid">
                (not really, just some useful data)
              </span>
              <dl className="g-tighter">
                <AboutItem title="Location">South-West Germany</AboutItem>
                <AboutItem title="Languages">Dutch, English, German</AboutItem>
                <AboutItem title="Pronouns">they/them</AboutItem>
                <AboutItem title="Professional skills">
                  <ul className="diamond-list diamond-list--inline">
                    <FilteredLI for="sound" exclusive>
                      Creative collaboration
                    </FilteredLI>
                    <FilteredLI for="sound">Sound Design</FilteredLI>
                    <FilteredLI for="sound">Reaper</FilteredLI>
                    <FilteredLI for="sound">SoundToys Suite</FilteredLI>
                    <FilteredLI for="sound">Izotope RX</FilteredLI>
                    <FilteredLI for="sound">Fabfilter</FilteredLI>
                    <FilteredLI for="sound">Wwise</FilteredLI>
                    <FilteredLI for="sound">Ableton Live</FilteredLI>
                    <FilteredLI for="sound">FMOD</FilteredLI>
                    <FilteredLI for="sound">Unity</FilteredLI>
                    <FilteredLI for="sound">Synthesis</FilteredLI>
                    <FilteredLI for="sound">Unreal</FilteredLI>
                    <li>Version Control (with git)</li>
                    <FilteredLI for="sound">ProTools (basic)</FilteredLI>

                    <FilteredLI for="webdev">Front-end development</FilteredLI>
                    <FilteredLI for="webdev">React</FilteredLI>
                    <FilteredLI for="webdev">Vanilla js</FilteredLI>
                    <FilteredLI for="webdev">Typescript</FilteredLI>
                    <FilteredLI for="webdev">
                      Single-Page Applications
                    </FilteredLI>
                    <FilteredLI for="webdev">GraphQL</FilteredLI>
                    <FilteredLI for="webdev">Node</FilteredLI>
                    <FilteredLI for="webdev">Redux</FilteredLI>
                    <FilteredLI for="webdev">HTML</FilteredLI>
                    <FilteredLI for="webdev">CSS</FilteredLI>
                    <FilteredLI for="webdev">Tailwind</FilteredLI>
                    <FilteredLI for="webdev">SCSS</FilteredLI>
                    <FilteredLI for="webdev">
                      iOS Development / Swift (beginner)
                    </FilteredLI>
                  </ul>
                </AboutItem>

                <AboutItem title="Other interests">
                  <ul className="diamond-list diamond-list--inline">
                    <li>Design</li>
                    <li>Typography</li>
                    <li>Music</li>
                    <li>Travelling</li>
                    <li>Photography / Cinematography</li>
                    <FilteredLI for="webdev">Sound Design</FilteredLI>
                    <FilteredLI for="sound">Web Development</FilteredLI>
                  </ul>
                </AboutItem>
              </dl>
            </section>

            <section className="n-spacing-top-medium">
              {" "}
              <h2>Experience:</h2>{" "}
              <dl className="g-tighter">
                <AboutExperienceItem
                  discipline="webdev"
                  company="Boords"
                  description="Web Developer"
                  timeFrame="August 2017 - present"
                />
                <AboutExperienceItem
                  discipline="webdev"
                  company="Zukini"
                  description="Attempt to build a SaaS product"
                  timeFrame="2016 - 2018"
                />
                <AboutExperienceItem
                  discipline="webdev"
                  company="Edenspiekermann"
                  description="Web Developer"
                  timeFrame="February - December 2015"
                />
                <AboutExperienceItem
                  discipline="webdev"
                  company="Freelance web developer"
                  description="various freelance, shared, and personal design or development projects"
                  timeFrame="2016 - present"
                />
                <AboutExperienceItem
                  discipline="webdev"
                  company="SoundCloud"
                  description="Frontend Engineer & Product Designer"
                  timeFrame="2011 - 2014"
                />
                <AboutExperienceItem
                  discipline="webdev"
                  company="campagnerotterdam"
                  description="UX Developer (after internship)"
                  timeFrame="September 2009 - February 2010"
                />
                <AboutExperienceItem
                  discipline="sound"
                  company="Track FM"
                  description="Audio branding (producing things like promos, sweepers and power intros)"
                  timeFrame="2005 - 2008"
                />
                <AboutExperienceItem
                  discipline="sound"
                  company="Won a national jingle contest"
                  description="Organised by radio.nl and Cue Creative (audio branding company). [Article (in dutch)](http://web.archive.org/web/20090527033404/http://www.radio.nl/2003/home/medianieuws/008.dossiers/005.op_bezoek_bij/053.cuecreative/default.asp?IntArticleID=119354&int_currentpage=1)"
                  timeFrame="2004"
                />
                <AboutExperienceItem
                  discipline="sound"
                  company="Radio & Sound branding for various stations"
                  description="Hosting and producing audio content"
                  timeFrame="mid 2000's - 2011"
                />
              </dl>
            </section>
          </aside>
        </div>
      </>
    )
  }
}

export default AboutPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        social {
          name
          url
        }
      }
    }
    bio: mdx(slug: { eq: "bio" }) {
      body
      ...MHExcerpt
      frontmatter {
        title
        slug
      }
    }
  }
`
