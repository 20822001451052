import React from "react"
import ReactMarkdown from "react-markdown"
import {
  ContentFilterContext,
  contentFilters,
  FilteredContent,
} from "./ContentFilter"

export const AboutItem: React.FC<{
  title: string
  children: React.ReactNode
}> = (props) => (
  <>
    <dt className="n-spacing-top-small">
      <h4 className="n-color-mid">{props.title}:</h4>
    </dt>
    <dd>{props.children}</dd>
  </>
)

export const AboutExperienceItem: React.FC<{
  discipline: contentFilters
  company: React.ReactNode
  description: string
  timeFrame: string
}> = (props) => {
  const { contentFilter } = React.useContext(ContentFilterContext)
  const icons: Record<contentFilters, string | undefined> = {
    all: undefined,
    sound: "🔈",
    webdev: "🖥",
  }

  return (
    <FilteredContent for={props.discipline}>
      <dt className="n-spacing-top-small">
        <h4>
          {contentFilter === "all" && <>{icons[props.discipline]}&nbsp;</>}
          <span className="n-color-mid">{props.company}</span>
        </h4>
      </dt>
      <dd>
        <ReactMarkdown>{props.description}</ReactMarkdown>
        <div className="n-type-tiny n-spacing-top-xxs">{props.timeFrame}</div>
      </dd>
    </FilteredContent>
  )
}
